import type { FC } from 'react';
import Image from '../Image';
import { IStoryPreview } from '@b2b-frontend/types';
import Button from '../Button';
import Heading from '../Heading';

interface IStoryProps {
	story: IStoryPreview;
	unoptimized?: boolean;
}

const Story: FC<IStoryProps> = ({ story, unoptimized = false }) => {
	return (
		<div>
			<div className="mx-auto w-full max-w-full relative">
				<Image
					image={story.image}
					layout="responsive"
					width={674}
					height={200}
					objectFit="cover"
					unoptimized={unoptimized}
				/>
			</div>
			<div
				className="flex flex-col items-center bg-white w-[85%] sm:w-[79%] md:w-[64%] mx-auto px-4 pt-8 mb-[-60px] pb-10"
				style={{ transform: `translateY(-60px)` }}
			>
				<Heading as={'h3'} size="text-base" className="text-brand mb-8 text-center px-4">
					{story.title}
				</Heading>
				<p className="text-xs mb-8 text-center max-w-full break-words">
					{story.previewText}
				</p>
				<Button href={story.href} style={{ width: '160px' }}>
					{story.ctaText}
				</Button>
			</div>
		</div>
	);
};

export default Story;
