import { getPublicSiteURL } from './env';

export const searchString = ({
	searchString,
	value,
}: {
	searchString?: string;
	value: string;
}): string[] | string | null => {
	if (!searchString) {
		return null;
	}

	const matches = new RegExp(searchString, 'i').exec(`${value}`);

	if (matches !== null) {
		const parts = value.split(matches[0]);

		return [parts[0], matches[0], parts[1]];
	}

	return value;
};

export const pluralise = (term: string, numberValue: number): string => {
	return `${term}${numberValue !== 1 ? 's' : ''}`;
};

export const ensureQueryParamIsString = (input: string | string[] | undefined) => {
	return Array.isArray(input) ? input[0] : input;
};

export const parseImageSrc = (src: string): string => {
	if (!src) {
		return '';
	}

	const isAdmin = process.env.NEXT_PUBLIC_WHICH_PACKAGE === 'admin';

	if (isAdmin && src.startsWith('/api/files')) {
		return src.replace('/api/files', `${getPublicSiteURL()}/api/files`);
	}

	return src;
};
