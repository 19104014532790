import React from 'react';
import EyeIcon from '@b2b-frontend/components/icons/EyeIcon';
import RepeatIcon from '@b2b-frontend/components/icons/RepeatIcon';
import ShippingIcon from '@b2b-frontend/components/icons/ShippingIcon';
import SunIcon from '@b2b-frontend/components/icons/SunIcon';
import PinIcon from '@b2b-frontend/components/icons/PinIcon';
import PageHeading from '../PageHeading';
import MaxWidthContainer from '../MaxWidthContainer';
import Heading from '../Heading';

const FeatureList = () => {
	return (
		<div className="border-t border-gray w-full bg-white">
			<MaxWidthContainer className="mb-20 px-2 sm:px-6" noPadding>
				<PageHeading>Why Shop Online</PageHeading>
				<ul className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 px-0 md:px-10 gap-x-6 md:gap-20 text-center">
					<li className="flex flex-col items-center relative mb-6 pt-20">
						<SunIcon className="absolute top-0 left-1/2 transform -translate-x-1/2 block mb-auto" />
						<Heading as={'h3'} className="mb-4" size="text-sm sm:text-base">
							24/7 Ordering
						</Heading>
						<p className="text-2xs sm:text-xs">
							Order cross-brand, whenever it suits you, with 24/7 ordering on any
							device.
						</p>
					</li>
					<li className="flex flex-col items-center relative mb-6 pt-20">
						<PinIcon className="absolute top-0 left-1/2 transform -translate-x-1/2 block mb-auto" />
						<Heading as={'h3'} className="mb-4" size="text-sm sm:text-base">
							Track deliveries
						</Heading>
						<p className="text-2xs sm:text-xs">
							Check the status of your orders at any time, including past orders.
						</p>
					</li>
					<li className="flex flex-col items-center relative mb-6 pt-20">
						<RepeatIcon className="absolute top-0 left-1/2 transform -translate-x-1/2 block mb-auto" />
						<Heading as={'h3'} className="mb-4" size="text-sm sm:text-base">
							Choose how you order
						</Heading>
						<p className="text-2xs sm:text-xs">
							Upload order forms, re-order from past deliveries, or utilise different
							listing views to order the way you want to.
						</p>
					</li>
					<li className="flex flex-col items-center relative mb-6 pt-20">
						<ShippingIcon className="absolute top-0 left-1/2 transform -translate-x-1/2 block mb-auto" />
						<Heading as={'h3'} className="mb-4" size="text-sm sm:text-base">
							Fast delivery
						</Heading>
						<p className="text-2xs sm:text-xs">
							The fastest way to get an order to our warehouse for fulfilment. No
							waiting around for your order to be processed, speeding up delivery
							time.
						</p>
					</li>
					<li className="flex flex-col items-center relative mb-6 pt-20">
						<EyeIcon className="absolute top-0 left-1/2 transform -translate-x-1/2 block mb-auto" />
						<Heading as={'h3'} className="mb-4" size="text-sm sm:text-base">
							Live stock visibility
						</Heading>
						<p className="text-2xs sm:text-xs">Stock levels updated daily.</p>
					</li>
				</ul>
			</MaxWidthContainer>
		</div>
	);
};

export default FeatureList;
