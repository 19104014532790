import SkeletonRow from '../SkeletonRow';
import React from 'react';

const StorySkeleton: React.FC = () => {
	return (
		<div>
			<SkeletonRow height="h-[200px]" width="100%" rounded={false} />
			<div
				className="flex flex-col items-center bg-white w-[85%] sm:w-[79%] md:w-[64%] mx-auto px-4 pt-8 mb-[-60px] pb-10"
				style={{ transform: `translateY(-60px)` }}
			>
				<SkeletonRow width="70%" height="h-6" className="mb-8" />
				<div className="flex flex-col items-center space-y-2 mb-8 w-full">
					<SkeletonRow width="95%" height="h-4" />
					<SkeletonRow width="90%" height="h-4" />
					<SkeletonRow width="100%" height="h-4" />
					<SkeletonRow width="55%" height="h-4" className="mb-8" />
				</div>
				<SkeletonRow width="160px" height="h-12" rounded={false} />
			</div>
		</div>
	);
};

export default StorySkeleton;
