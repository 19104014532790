import React, { useState } from 'react';
import clsx from 'clsx';
import Image from 'next/legacy/image';
import Link from '@b2b-frontend/components/Link';
import { IImage } from '@b2b-frontend/types';

interface IBannerProps {
	className?: string;
	image?: IImage & {
		blurDataURL?: string;
	};
}

const Banner: React.FC<IBannerProps> = ({ image, className = '' }) => {
	const [showImage, setShowImage] = useState(true);

	const handleImageLoadError = () => {
		setShowImage(false);
	};

	const WrapperTag = !!image?.href ? Link : 'div';

	if (!showImage) {
		return null;
	}

	const wrapperProps = {};

	if (image?.href) {
		wrapperProps['href'] = image.href;
	}

	return (
		<div className={clsx('relative max-w-[1920px] mx-auto w-full pb-full md:pb-0', className)}>
			<WrapperTag className="relative mx-auto w-screen max-w-full" {...(wrapperProps as any)}>
				<Image
					alt={image?.alt || ''}
					src={image?.src || ''}
					blurDataURL={image?.blurDataURL}
					placeholder={image?.blurDataURL ? 'blur' : 'empty'}
					layout="responsive"
					width={1920}
					height={430}
					objectFit="cover"
					priority
					unoptimized
					quality={80}
					onError={handleImageLoadError}
				/>
			</WrapperTag>
		</div>
	);
};

export default Banner;
