import { PAGE_CATEGORY } from '@b2b-frontend/utils/analytics/constants';

export { default } from 'views/Home';

export const getServerSideProps = () => {
	return {
		props: {
			dataLayerSeed: {
				pageCategory: PAGE_CATEGORY.HOMEPAGE,
			},
		},
	};
};
