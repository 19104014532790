import { returnKeyIfParamsAreDefined } from '@b2b-frontend/utils/fetcher';
import { useSiteId } from '@b2b-frontend/utils/sites';
import useSWR from 'swr';
import useUser from 'utils/useUser';

export const useBestSellingProductsListing = () => {
	const { isInitialised } = useUser();
	const { data: products = [], isValidating: isFetchingProducts } = useSWR(() =>
		returnKeyIfParamsAreDefined('/api/best-sellers?sort_mode=top_sellers&limit=24', [
			isInitialised,
		]),
	);

	return {
		products,
		isFetchingProducts,
	};
};
