import { FC } from 'react';
import useSWR from 'swr';
import StorySkeleton from '@b2b-frontend/components/Story/skeleton';
import MaxWidthContainer from 'components/MaxWidthContainer';
import PageHeading from 'components/PageHeading';
import Story from 'components/Story';

const HomeStoryBlock: FC = () => {
	const { data: stories = [], isValidating } = useSWR('/api/stories');

	if (stories.length === 0 && !isValidating) {
		return null;
	}

	return (
		<MaxWidthContainer className="mb-5" padding="px-4 md:px-6 2xl:px-0">
			<PageHeading>Feature Stories</PageHeading>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
				{isValidating ? (
					<>
						<StorySkeleton />
						<StorySkeleton />
					</>
				) : (
					stories.slice(0, 2).map(story => {
						return <Story story={story} key={story.id} unoptimized />;
					})
				)}
			</div>
		</MaxWidthContainer>
	);
};

export default HomeStoryBlock;
