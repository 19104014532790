import React, { useMemo } from 'react';
import NextImage, { ImageProps } from 'next/legacy/image';
import { IImage } from '@b2b-frontend/types';
import { getPublicSiteURL } from '@b2b-frontend/utils/env';
import { parseImageSrc } from '@b2b-frontend/utils/string';

const Image: React.FC<
	Partial<ImageProps> & {
		image: IImage;
		width: number;
		height: number;
		showPlaceholder?: boolean;
	}
> = ({ image, height, width, showPlaceholder = false, ...otherProps }) => {
	const src = useMemo(() => {
		if (showPlaceholder && !image?.src) {
			return `https://via.placeholder.com/${width}x${height}`;
		}

		return parseImageSrc(image?.src);
	}, [height, image?.src, showPlaceholder, width]);

	return (
		<NextImage
			{...otherProps}
			unoptimized
			src={src}
			alt={image?.alt || ''}
			height={height}
			width={width}
		/>
	);
};

export default Image;
