import React, { useContext, useMemo, useRef } from 'react';
import clsx from 'clsx';
import ChevronLeftIcon from '@b2b-frontend/components/icons/ChevronLeftIcon';
import { IProduct } from '@b2b-frontend/types';
import ProductCarouselItemSkeleton from '../ProductGridItem/components/Skeleton';
import CommonProductTile from 'components/CommonProductTile';
import { ResizeContext } from 'utils/context';

const Skeleton: React.FC<{ pageSize: number }> = ({ pageSize }) => {
	return (
		<>
			{new Array(pageSize)
				.fill(<ProductCarouselItemSkeleton className={carouselItemClasses} />)
				.map((product, index) => {
					return <React.Fragment key={index}>{product}</React.Fragment>;
				})}
		</>
	);
};

const carouselItemClasses =
	'carousel-item w-full snap-center sm:snap-start grow shrink-0 sm:basis-6/12 lg:basis-1/4 sm:w-1/2 lg:w-1/4 motion-safe:transition-transform ease-in-out duration-400 border-r last:border-r-0 border-gray';

const ProductCarousel: React.FC<{
	products: IProduct[];
	isFetching: boolean;
}> = ({ products, isFetching }) => {
	const { size } = useContext(ResizeContext);
	const scrollContainer = useRef<HTMLDivElement>(null);

	const pageSize = useMemo(() => {
		if (size === 'large') {
			return 4;
		}

		if (size === 'medium' || size === 'small') {
			return 2;
		}

		return 1;
	}, [size]);

	const supportsTouch = useMemo(() => {
		if (typeof window === 'undefined') {
			return false;
		}

		return navigator.maxTouchPoints > 0;
	}, []);

	const decrementCurrentIndex = () => {
		const scrollItem = scrollContainer.current?.querySelector('.carousel-item');

		if (scrollItem) {
			const { width } = scrollItem.getBoundingClientRect();
			scrollContainer.current?.scrollBy({
				behavior: 'smooth',
				left: 0 - width,
			});
		}
	};

	const incrementCurrentIndex = () => {
		const scrollItem = scrollContainer.current?.querySelector('.carousel-item');

		if (scrollItem) {
			const { width } = scrollItem.getBoundingClientRect();
			scrollContainer.current?.scrollBy({
				behavior: 'smooth',
				left: width,
			});
		}
	};

	return (
		<div className="flex items-center w-full max-w-[1640px] mx-auto justify-between">
			<button
				className={clsx(
					supportsTouch ? 'hidden sm:inline-flex' : 'inline-flex',
					'shrink-0 justify-center items-center w-8 h-8 lg:w-10 lg:h-10 bg-gray-extra-light top-50% hover:bg-gray focus:ring-2 focus:ring-gray focus:outline-none',
				)}
				onClick={decrementCurrentIndex}
			>
				<span className="sr-only">Scroll carousel left</span>
				<ChevronLeftIcon />
			</button>
			<div className="border border-gray max-w-[1364px] mx-auto grow-1 overflow-x-hidden w-full">
				<div
					ref={scrollContainer}
					className="whitespace-nowrap flex overflow-x-auto snap-x max-w-full snap-mandatory"
				>
					{isFetching ? (
						<Skeleton pageSize={pageSize} />
					) : (
						products.map((product, index) => {
							return (
								<CommonProductTile
									data-index={index}
									key={index}
									{...product}
									listPosition={index}
									activeView="grid"
									priceElementReferenceViewport={
										scrollContainer.current ?? undefined
									}
									className={carouselItemClasses}
								/>
							);
						})
					)}
				</div>
			</div>
			<button
				className={clsx(
					supportsTouch ? 'hidden sm:inline-flex' : 'inline-flex',
					'shrink-0 justify-center items-center w-[36px] h-[36px] lg:w-[45px] lg:h-[45px] bg-gray-extra-light top-50% hover:bg-gray focus:ring-2 focus:ring-gray focus:outline-none',
				)}
				onClick={incrementCurrentIndex}
			>
				<span className="sr-only">Scroll carousel right</span>
				<ChevronLeftIcon className="transform rotate-180" />
			</button>
		</div>
	);
};

export default ProductCarousel;
